<template>
  <a-card :bordered="false">
    <a-row class="query-row" type="flex" :gutter="12">
      <a-col style="width: 220px">
        <span>手机号：</span>
        <a-input
          v-model="queryData.mobile"
          allowClear
          placeholder="请输入"
        ></a-input>
      </a-col>
      <a-col style="width: 220px">
        <span>会议码：</span>
        <a-input
          v-model="queryData.code"
          allowClear
          placeholder="请输入"
        ></a-input>
      </a-col>

      <a-col>
        <a-button @click="search" type="primary" style="margin-right: 12px">
          查询
        </a-button>
      </a-col>
    </a-row>

    <a-table
      :columns="columns"
      :dataSource="tableData"
      :pagination="pagination"
      :rowKey="(record) => record.id"
      :loading="loading"
      :scroll="{ x: true }"
      size="default"
      :locale="{ emptyText: emptyText }"
      @change="handleTableChange"
    >
    </a-table>
  </a-card>
</template>

<script>
import api from '@/api';
import { SUCCESS_CODE } from '../../config';
import { TELL_PHONE, NUMBER } from '../../libs/validator';

export default {
  data() {
    return {
      emptyText: '请搜索用户查询数据',
      loading: false,
      tableData: [],
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
        size: 'small',
        showLessItems: true,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: total => `共 ${total} 条`
      },
      queryData: {},
      columns: [
        {
          title: '日期',
          dataIndex: 'date',
          align: 'center',
          ellipsis: true
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          align: 'center'
        },
        {
          title: '姓名',
          dataIndex: 'name',
          align: 'center'
        },
        {
          title: '会议码',
          dataIndex: 'code',
          align: 'center',
          ellipsis: true
        },
        {
          title: '历史总粉丝数',
          dataIndex: 'historyFansNum',
          align: 'center',
          ellipsis: true
        },
        {
          title: '当前粉丝数',
          dataIndex: 'currentFansNum',
          align: 'center',
          ellipsis: true
        },
        {
          title: '首单推荐数',
          dataIndex: 'firstOrderNum',
          align: 'center',
          ellipsis: true
        },
        {
          title: '激活人数',
          dataIndex: 'activeFansNum',
          align: 'center',
          ellipsis: true
        },
        {
          title: '直推人数',
          dataIndex: 'pushFansNum',
          align: 'center',
          ellipsis: true
        },
        {
          title: '销售业绩',
          dataIndex: 'saledMoney',
          align: 'center',
          ellipsis: true
        },
        {
          title: '直推销售业绩',
          dataIndex: 'pushSaledMoney',
          align: 'center',
          ellipsis: true
        }
      ]
    };
  },
  methods: {
    async getTableData(options) {
      let params = {
        pageNum: 1,
        pageSize: this.pagination.pageSize || 10,
        ...options
      };
      const { mobile, code: searchCode } = this.queryData;
      if (!(mobile || searchCode)) {
        this.$message.error('请输入手机号或会议码查询');
        return;
      }

      if (mobile && !TELL_PHONE.test(mobile)) {
        this.$message.error('请输入正确的手机号');
        return;
      }
      if (searchCode && !NUMBER.test(searchCode)) {
        this.$message.error('请输入正确的会议码');
        return;
      }

      if (mobile) {
        params.mobile = mobile;
      }

      if (searchCode) {
        params.code = searchCode;
      }

      this.loading = true;
      const { code, data } = await api.lecturer.lecturerRecord(params);
      if (code === SUCCESS_CODE) {
        const { dataList, pageNum, pageSize, total } = data;
        this.tableData = !!dataList ? dataList : [];
        this.emptyText = '暂无数据';

        this.pagination = !!dataList
          ? {
              ...this.pagination,
              current: pageNum,
              pageSize,
              total
            }
          : {};
      }
      this.loading = false;
    },
    search() {
      this.getTableData();
    },
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      const param = {
        pageNum: current,
        pageSize
      };
      this.getTableData(param);
    }
  }
};
</script>